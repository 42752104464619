.ant-layout-sider {
  background-color: #0e0460;
}

.layout {
  background: radial-gradient(50% 50% at 50% 50%, #07022e 0%, #0e0460 100%);
  background-size: "cover";
}

/* TO BE IMPROVED...
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
  position: fixed;
  width: 200px;
}
*/

.cancelled-order td {
  background-color: #f8e0dd;
}
.cancelled-order td.ant-table-cell-row-hover {
  background-color: #f8e0dd !important;
}

.supplement-unified td {
  background-color: #b8b3b2;
}
.supplement-unified td.ant-table-cell-row-hover {
  background-color: #b8b3b2 !important;
}

.return-order td {
  background-color: #f2e6d6;
}

.return-order td.ant-table-cell-row-hover {
  background-color: #f2e6d6 !important;
}

.disabled-item td {
  background-color: #9292908b;
}

.disabled-item td.ant-table-cell-row-hover {
  background-color: #9292908b !important;
}

#issues_files-form .ant-upload-list-item-card-actions-btn {
  display: none;
}

.content-start {
  align-content: flex-start;
}

.warning-row td {
  background-color: #fffbe6;
}

.warning-row td.ant-table-cell-row-hover {
  background-color: #fffbe6 !important;
}

.error-row td {
  background-color: #fff2f0;
}

.error-row td.ant-table-cell-row-hover {
  background-color: #fff2f0 !important;
}

.duplicates-row td {
  background-color: #f5d5b9;
}

.duplicates-row td.ant-table-cell-row-hover {
  background-color: #f5d5b9 !important;
}

.pdf-preview-modal .ant-modal {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.pdf-preview-modal .ant-modal .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.pdf-preview-modal .ant-modal .ant-modal-content .ant-modal-close {
  color: #fff
}

.user-normal {
  background-color: transparent;
}

.user-warning {
  background-color: darkkhaki;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}
